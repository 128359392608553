
// Category click event
$('.category .location-category').click(function (event) {

  event.preventDefault()
  gMap.hideInfoWindows()
  var parent = $(this).parent()
  var hasSelected = parent.hasClass('selected')
  $('.selected.category').removeClass('selected')

  // if (this).parent().hasclass(.selected) then close and return out of function
  if(hasSelected){
      $('.neighborhood-list-wrap').not('#content' + category).slideUp()
      for (var key in gMap.markers.slice(1+1)) { // 1 for property marker
        var offset = parseInt(key) + 1 +1; // 1 for property marker
        gMap.markers[offset].setVisible(false)
      }
      gMap.refresh()
      return;
  }

  parent.addClass('selected')
  $('.category.active-category').parent().removeClass('active-category')
  parent.addClass('active-category')


  $('#list-container').toggleClass('show-list-container')
  var category = parseInt($(this).attr('data-category'))

  for (var key in gMap.markers.slice(2)) {
    gMap.markers[parseInt(key) + 2].setVisible(false)
  }

  $('#' + 'results' + category + ' a').each(function () {
    var index = parseInt($(this).attr('data-marker-index'))
    gMap.markers[index].setVisible(true)
  })

  $('.neighborhood-list-wrap').not('#content' + category).slideUp()
  $('#content' + category).slideDown(400, function () {
    // Fit all of the visible markers in a map
    gMap.fitZoom()
    gMap.refresh()
    var cnt = gMap.getCenter();
    cnt.e+=0.000001
    gMap.panTo(cnt)
    cnt.e-=0.000001
    gMap.panTo(cnt)
  })
})


// Location link to marker
$('.pan-to-marker').click(function (event) {
  event.preventDefault()
  var position, lat, lng, $index

  $index = $(this).data('marker-index')
  $catIndex = $(this).data('category-marker-index')
  $('.neighborhood-list .active').removeClass('active')
  $(this).parent().addClass('active')

  // Open the infoWindow
  gMap.hideInfoWindows()
  gMap.markers[$index].infoWindow.open(gMap.map, gMap.markers[$index])

  position = gMap.markers[$index].getPosition()
  lat = position.lat()
  lng = position.lng()
  gMap.setCenter(lat, lng)
})

$('.custom-zoom-levels a').click(function (event) {
  event.preventDefault()

  var zoomLevelLat = $(this).data('centerlat')
  var zoomLevelLng = $(this).data('centerlng')
  var zoomLevel = $(this).data('zoomlevel')

  gMap.setCenter(zoomLevelLat, zoomLevelLng)
  gMap.setZoom(zoomLevel)
})
